<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="3">
                        <b-form-group labe-for="Reports" label="Choose Farmer Report">
                            <validation-provider #default="{ errors }" name="report_type" rules="">
                                <b-form-select id="report_type" name="report_type" size="md" v-model="report_type">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option value="1">
                                        Farmer Purchase Report
                                    </b-form-select-option>
                                    <b-form-select-option value="2">
                                        Farmers Report
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.report_type">{{
                                serverErrors.report_type[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-if="report_type === '1'">
                    <b-col cols="2" md="1">
                        <label>
                            Season <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="season_id"
                            @change="changeSeason()"
                            :disabled="seasons.length <= 1"
                        >
                            <b-form-select-option :value="null" v-if="seasons.length > 1">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <!-- <b-col cols="6" md="2">
                        <label>
                            CPP <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="market_id"
                            @change="changeMarket()"
                        >
                            <b-form-select-option
                                :value="null"
                                selected
                            >
                                Select...
                            </b-form-select-option>
                            <b-form-select-option
                                v-for="market in markets"
                                :key="market.id"
                                :value="market.id"
                            >
                                {{ market.code.toUpperCase() }}: {{ market.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col> -->
                    
                    <b-col cols="6" md="2">
                        <label>
                            Farmer Number: <small class="text-danger" v-if="serverErrors && serverErrors.grower_id">{{ serverErrors.grower_id[0] }}</small>
                        </label>

                        <b-form-input
                            id="regNumber"
                            name="regNumber"
                            size="sm"
                            v-model="regNumber"
                        /> 
                    </b-col>


                    <b-col cols="2" md="2">
                        <label>
                         From <small class="text-danger"></small> 
                        </label>
                                    <validation-provider #default="{ errors }" name="StartDate" rules="">
                                        <b-form-datepicker
                                            id="startDate" v-model="startDate" name="startDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.startDate">{{ serverErrors.startDate[0] }}</small>
                                    </validation-provider>
                            </b-col>
                            <b-col cols="2" md="2">
                                <label>
                                    To <small class="text-danger"></small> 
                                    </label> 
                                    <validation-provider #default="{ errors }" name="End Date" rules="">
                                        <b-form-datepicker
                                            id="endDate" v-model="endDate" name="endDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.endDate">{{ serverErrors.endDate[0] }}</small>
                                    </validation-provider>
                    </b-col>

                  <b-col cols="6" md="2" class="pt-2">
                         <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport()"
                            class="ml-2"
                        >
                            <b-spinner small class="ml-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Print Report</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>

                </b-row>

                <b-row v-if="report_type === '2'">
                    <b-col cols="6" md="2">
                        <label>
                            Season <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="season_id"
                            @change="changeSeason()"
                            :disabled="seasons.length <= 1"
                        >
                            <b-form-select-option :value="null" v-if="seasons.length > 1">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col
                        cols="12"
                        md="2"
                        class="mb-md-0 mb-2"
                        v-for="(toSelect, index) in selections"
                        :key="toSelect.level"
                    >
                        <label>
                            {{ toSelect.label }} <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{ serverErrors.location_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="toSelect.model"
                            @change="changeFilters(toSelect.model, index)"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="location in toSelect.locations"
                                :key="location.id"
                                :value="location"
                            >
                                {{ location.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="2" md="2">
                        <label>
                         From <small class="text-danger"></small> 
                        </label>
                                    <validation-provider #default="{ errors }" name="StartDate" rules="">
                                        <b-form-datepicker
                                            id="startDate" v-model="startDate" name="startDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.startDate">{{ serverErrors.startDate[0] }}</small>
                                    </validation-provider>
                            </b-col>
                            <b-col cols="2" md="2">
                                <label>
                                    To <small class="text-danger"></small> 
                                    </label> 
                                    <validation-provider #default="{ errors }" name="End Date" rules="">
                                        <b-form-datepicker
                                            id="endDate" v-model="endDate" name="endDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.endDate">{{ serverErrors.endDate[0] }}</small>
                                    </validation-provider>
                        </b-col>
                    <b-col cols="6" md="2" class="pt-2">
                        <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport2()"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Generate</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
    } from 'bootstrap-vue'

    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import vSelect from 'vue-select'
    import growerVoucherStoreModule from '@/views/cromis/reports/farmers/growerVoucherStoreModule'
    import villageSalesStoreModule from '@/views/cromis/reports/loans/villageSalesStoreModule'
    import axios from '@axios'
    import useGrowerVoucherList from '@/views/cromis/reports/farmers/useGrowerVoucherList'
    
    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
            BSpinner, BBadge,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context){
            const dataForm = ref(null)
            const myModal = ref(null)
            const saving = ref(false)
            const serverErrors = ref({})

            const seasons = ref([])
            const season_id = ref(null)
            const location_id = ref(null)
            const markets = ref([])
            const reports = ref([])
            const market_id = ref(null)
            const selections = ref([])
            const formSelections = ref([])
            const levels = ref(null)
            const children = ref([])
            const sales = ref([])
            const sale_id = ref(null)
            const regNumber = ref(null)
            const startDate = ref(null)
            const report_type = ref(null)
            const endDate = ref(null)
            const growers = ref([])
            const grower_id = ref(null)
            const number = ref(null)

            const grower = ref({
            id: null,
            regNumber: null,
            startDate: null,
            endDate: null,
        })

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            resolveTypeVariant,
            locationFilter,
        } = useGrowerVoucherList()

            const CROMIS_STORE_MODULE_NAME = 'cromis-growerVoucher'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, growerVoucherStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-growerVoucher/seasons')
                            .then(response => {
                                seasons.value = response.data.seasons
                                if(seasons.value.length === 1){
                                    season_id.value = seasons.value[0].id
                                    changeSeason()
                                }
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            })

            const changeSeason = async () => {
                if(season_id.value){
                    market_id.value = null
                    await store.dispatch('cromis-growerVoucher/markets', { season_id: season_id.value, report: true })
                                .then(response => {
                                    markets.value = response.data.markets
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    if(markets.value.length > 0){
                        markets.value.splice(0)
                    }
                }

                serverErrors.value.season_id = null
            }

            const changeMarket = async () => {
                sale_id.value = null
                if(market_id.value){
                    await store.dispatch('cromis-growerVoucher/sales', { season_id: season_id.value, market_id: market_id.value, report: true })
                                .then(response => {
                                    sales.value = response.data.sales
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    if(sales.value.length > 0){
                        sales.value.splice(0)
                    }
                }

                serverErrors.value.market_id = null
            }

            const changeGrower = async () => {
                sale_id.value = null
                if(grower_id.value){
                    await store.dispatch('cromis-growerVoucher/sales', { season_id: season_id.value, market_id: market_id.value,grower_id: grower_id.value,report: true })
                                .then(response => {
                                    sales.value = response.data.sales
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    if(sales.value.length > 0){
                        sales.value.splice(0)
                    }
                }

                serverErrors.value.grower_id = null
            }

            const changeSale = async () => {
                grower_id.value = null
                number.value = null
                if(sale_id.value){
                    await store.dispatch('cromis-growerVoucher/growers', { sale_id: sale_id.value, report: true })
                                .then(response => {
                                    growers.value = response.data.growers
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    if(growers.value.length > 0){
                        growers.value.splice(0)
                    }
                }

                serverErrors.value.sale_id = null
            }

        
            const generateReport = async () => {
                grower_id.value = null
                let hasError = false
                serverErrors.value = {
                    season_id: null,
                    market_id: null,
                    grower_id: null,
                    regNumber: null,
                    startDate: null,
                    endDate: null,
                }

                console.log('Growers: ',growers,'grower_id',grower_id,'season_id: ',season_id,'market_id: ',market_id,'startDate: ',startDate,'endDate: ',endDate,'regNumber',regNumber);

                if(!season_id.value){
                    hasError = true
                    serverErrors.value.season_id = ['Specify season']
                }

                if(!regNumber.value){
                    hasError = true
                    serverErrors.value.regNumber = ['Specify Farmer Number']
                }
                
                if(!startDate.value){
                    hasError = true
                    serverErrors.value.startDate = ['Specify From Date']
                }

                if(!endDate.value){
                    hasError = true
                    serverErrors.value.endDate = ['Specify To Date']
                }

                if(!hasError){
                    saving.value = true

                    await window.open(`${ axios.defaults.baseURL }/reports/farmer_reports?season_id=${ season_id.value }&market_id=${ market_id.value }&startDate=${ startDate.value }&endDate=${ endDate.value }&sale_id=${ sale_id.value }&regNumber=${ regNumber.value }`, '_blank')
                    saving.value = false
                }
            }

            const CROMIS_STORE_MODULE_NAME2 = 'cromis-villageSale'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME2)) store.registerModule(CROMIS_STORE_MODULE_NAME2, villageSalesStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME2)) store.unregisterModule(CROMIS_STORE_MODULE_NAME2)
            })

            onMounted(async () => {
                await store.dispatch('cromis-villageSale/levels')
                        .then(response => {
                            levels.value = response.data.levels[0]

                            selections.value.push({
                                level: levels.value.name.toLowerCase(),
                                model: null,
                                label: levels.value.name,
                                child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                                locations: [],
                            })

                            formSelections.value.push({
                                level: levels.value.name.toLowerCase(),
                                model: null,
                                label: levels.value.name,
                                child: levels.value.children.length > 0? levels.value.children[0].name.toLowerCase() : null,
                                locations: [],
                            })

                            let list = levels.value.children

                            while(list.length > 0){
                                children.value.push(list[0])
                                if(list[0].children > 0){
                                    selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                                    formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                                }
                                else{
                                    selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                                    formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                                }

                                list = list[0].children
                            }
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })

                await store.dispatch('cromis-villageSale/locations')
                            .then(response => {
                                populateSelections(response.data.locations)
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })

                await store.dispatch('cromis-villageSale/seasons')
                            .then(response => {
                                seasons.value = response.data.seasons
                                if(seasons.value.length === 1){
                                    season_id.value = seasons.value[0].id
                                    changeSeason()
                                }
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            })

            const populateSelections = (locations) => {
                for(let i = 0; i < locations.length; i++){
                    for(let x = 0; x < selections.value.length; x++){
                        if(locations[i].level.name.toLowerCase() === selections.value[x].level){
                            selections.value[x].locations.push(locations[i])

                            if(x === 0){
                                formSelections.value[x].locations.push(locations[i])
                            }
                        }
                    }
                }
            }

            const changeSeason2 = async () => {
                await store.dispatch('cromis-villageSale/sales', { season_id: season_id.value, report: true })
                            .then(response => {
                                sales.value = response.data
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            }

            const changeFilters = async (location, index) => {
                if(!location){
                    for(let i = index + 1; i < selections.value.length; i++){
                        selections.value[i].locations.splice(0)
                        selections.value[i].model = null
                    }

                    if(index > 0){
                        location_id.value = selections.value[index - 1].model.id
                    }
                    else{
                        location_id.value = null
                    }
                }
                else{
                    if(selections.value[index + 1]){
                        selections.value[index + 1].locations.splice(0)
                    }

                    location.children.map((child) => {
                        selections.value[index + 1].locations.push(child)
                    })

                    if(location.children.length > 0){
                        selections.value[index + 1].model = null
                    }

                    location_id.value = selections.value[index].model.id
                }
            }

            const generateReport2 = async () => {
                let hasError = false
                serverErrors.value = {
                    season_id: null,
                    location_id: null,
                    startDate: null,
                    endDate: null,
                    number: null
                }

                if(!season_id.value){
                    hasError = true
                    serverErrors.value.season_id = ['Specify season']
                }

                if(!location_id.value){
                    hasError = true
                    serverErrors.value.location_id = ['Select village']
                }

                if(!startDate.value){
                    hasError = true
                    serverErrors.value.startDate = ['From Date required']
                }

                if(!endDate.value){
                    hasError = true
                    serverErrors.value.endDate = ['End Date required']
                }

                if(!hasError){
                    saving.value = true

                    await window.open(`${ axios.defaults.baseURL }/reports/farmer_reports?season_id=${ season_id.value }&location_id=${ location_id.value }&startDate=${ startDate.value}&endDate=${ endDate.value}`, '_blank')
                    saving.value = false
                }
            }
            return {
                dataForm,
                myModal,
                saving,
                serverErrors,
                reports,
                seasons,
                selections,
                formSelections,
                levels,
                children,
                season_id,
                location_id,
                markets,
                market_id,
                report_type,
                sales,
                startDate,
                endDate,
                regNumber,
                sale_id,
                grower,
                growers,
                grower_id,
                number,
                changeFilters,
                changeSeason2,
                changeSeason,
                changeGrower,
                changeMarket,
                changeSale,
                generateReport,
                generateReport2,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>